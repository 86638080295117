import React from "react";
import {
  Dialog,
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "@gonoodle/gn-universe-ui";

import ChampionSelect from "./ChampionSelect";
import { useChampionsQuery, useProfile } from "../hooks";

export default function GraduatedChampSelection({ champ, onChampSelected }) {
  const { champions } = useChampionsQuery();
  const { profile } = useProfile();
  const [resetChampionsProgress, setResetChampionsProgress] = React.useState(
    false,
  );
  const [unavailableChampionsIDs, setUnavailableChampions] = React.useState(
    profile.graduatedChampIds.concat([champ.champId]),
  );

  const defaultChampion = champions.find(
    (c) => !unavailableChampionsIDs.includes(c.id),
  );
  const needsToResetChampions =
    champions &&
    champions.length > 0 &&
    unavailableChampionsIDs.length >= champions.length;

  function handelSelectedChampionChange(champion) {
    onChampSelected(champion.id, resetChampionsProgress);
  }

  return (
    <>
      <Dialog.Root className="z-50 px-0 items-stretch" isOpen>
        <Dialog.Content className="bg-gray-900 overflow-auto w-screen h-screen">
          <div className="w-full h-full p-md">
            <Dialog.Title className="font-display text-2xl lg:text-4xl mb-xl lg:mt-lg text-white text-center capitalize">
              Time to pick a new champ!
            </Dialog.Title>

            <ChampionSelect
              defaultSelectedChampion={defaultChampion}
              unavailableChampionsIDs={unavailableChampionsIDs}
              onChange={handelSelectedChampionChange}
              disabled={needsToResetChampions}
            />
          </div>
        </Dialog.Content>
      </Dialog.Root>

      <Dialog.Root
        isOpen={needsToResetChampions}
        className="z-[60] px-0 items-center"
      >
        <Dialog.Content className="inline-block w-full md:w-screen-xs p-md my-8 self-center align-middle bg-white rounded-lg text-center overflow-hidden transform transition-all">
          <div className="flex flex-col space-y-md">
            <Dialog.Title className="gn-headline-sm">
              Wow! Congratulations!
            </Dialog.Title>
            <p className="gn-text">
              You&apos;ve maxed out ALL of the Champs. What an achievement!
              Let&apos;s see if you can do it again.
            </p>
            <Button
              variant={BUTTON_VARIANTS.darkOutline}
              size={BUTTON_SIZES.md}
              onPress={() => {
                setResetChampionsProgress(true);
                setUnavailableChampions([]);
              }}
            >
              Reset your Champs
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
