"use client";

import React from "react";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import get from "lodash/get";
import {
  Modal,
  Image,
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  Pressable,
} from "@gonoodle/gn-universe-ui";
import { StarIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";
import { usePathname } from "next/navigation";
import { usePreviousImmediate } from "rooks";
import {
  SECTIONS,
  SECTIONS_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { useUser } from "../user";
import {
  useCurrentChampion,
  useLoggedOutChampPoints,
  useChampionsQuery,
  useProfile,
  useOnce,
  useIntendedPath,
  useRouter,
} from "../../hooks";
import { ROUTE_PATHS, QUERY_KEYS, URLS } from "../../constants";
import PointsProgressBar from "../../components/PointsProgressBar";
import GraduatedChampSelection from "../../components/GraduatedChampSelection";
import { useLogEvent } from "../Analytics";
import api from "../../api";
import Player from "../../components/Player";

const client = api();

const transitionTypes = {
  pointAdded: "pointAdded",
  levelUp: "levelUp",
  graduate: "graduate",
};

const missingTransmogrifierProvider =
  "You forgot to wrap your app in <TransmogrifierProvider>";

export const TransmogrifierContext = React.createContext({
  get points() {
    throw new Error(missingTransmogrifierProvider);
  },
  get onPointsAdded() {
    throw new Error(missingTransmogrifierProvider);
  },
});

TransmogrifierContext.displayName = "TransmogrifierContext";

let pointsAddedListeners = [];
const subscribeToPointsAdded = (listener) => {
  pointsAddedListeners.push(listener);

  return () => {
    pointsAddedListeners = pointsAddedListeners.filter((l) => l !== listener);
  };
};

const SYNC_EARNED_POINTS_KEY = "syncEarnedPoints";

export default function TransmogrifierProvider({ children }) {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { profile } = useProfile();
  const prevProfileId = usePreviousImmediate(profile?.id);
  const champion = useCurrentChampion();
  const prevChampion = usePreviousImmediate(champion);
  const [transition, setTransition] = React.useState();
  const [earnedPoints, setEarnedPoints] = React.useState(0);
  const [debouncedPoints, setDebouncedPoints] = React.useState(0);
  const { loggedOutChampPoints, incrementLoggedOutChampPoints } =
    useLoggedOutChampPoints();

  const points = !champion
    ? 0
    : user.isLoggedIn
    ? champion.pointsInLevel
    : loggedOutChampPoints;
  const canEarnPoints = debouncedPoints < points && earnedPoints;

  // Function to update points in localStorage so that it can be shared across tabs.
  const notifyPointsChange = (newPoints) => {
    localStorage.setItem(SYNC_EARNED_POINTS_KEY, JSON.stringify(newPoints));
  };

  const updateDebouncedPoints = React.useCallback((newPoints) => {
    setDebouncedPoints(newPoints);
    notifyPointsChange(newPoints);
  }, []);

  React.useEffect(() => {
    // once the champion is set, we set the debounced points to the current points.
    if (!prevChampion && champion) {
      updateDebouncedPoints(points);
    }

    if (prevChampion && champion && prevChampion.id !== champion.id) {
      updateDebouncedPoints(points);
      setEarnedPoints(0);
    }
  }, [champion, points, prevChampion, updateDebouncedPoints]);

  React.useEffect(() => {
    if (profile?.id !== prevProfileId) {
      updateDebouncedPoints(points);
      setEarnedPoints(0);
    }
  }, [points, prevProfileId, profile?.id, updateDebouncedPoints]);

  React.useEffect(() => {
    const onPointsSync = (event) => {
      if (event.key === SYNC_EARNED_POINTS_KEY) {
        const updatedPoints = JSON.parse(event.newValue);

        setDebouncedPoints(updatedPoints); // Update local state with the new points from other tabs
      }
    };

    window.addEventListener("storage", onPointsSync);

    return () => {
      window.removeEventListener("storage", onPointsSync);
      localStorage.removeItem(SYNC_EARNED_POINTS_KEY);
    };
  }, []);

  /**
   * Logic to be used when points are added to the champion at the current video session.
   * The useVideoSession gets the earned points during a session and calls this callback, However since it is a singleton
   * hook it can only be used in one place.
   * As a workaround, we are using this callback to get the earned points.
   * It dose not update the points in the transmogrifier context, it only serves as a way to get the earned points.
   * TODO: Remove once the useVideoSession hook is refactored to be used in multiple places.
   */

  const onPointsAdded = React.useCallback(
    (amount) => {
      if (!user.isLoggedIn) {
        incrementLoggedOutChampPoints(amount);
      }

      pointsAddedListeners.forEach(
        (listener) => typeof listener === "function" && listener(amount),
      );

      setEarnedPoints(amount);
    },
    [incrementLoggedOutChampPoints, user.isLoggedIn],
  );

  React.useEffect(
    () => () => {
      pointsAddedListeners = [];
    },
    [],
  );

  const earnPoints = React.useCallback(() => {
    updateDebouncedPoints(points);
  }, [points, updateDebouncedPoints]);

  const { mutate: levelUpChamp } = useMutation({
    mutationFn: client.levelUpChamp,
    onSuccess: async (updatedChampion) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROFILE_CHAMPIONS],
      });

      // Extra earned points are added to the next level, so we need to sync the progress bar.
      updateDebouncedPoints(updatedChampion.pointsInLevel);
      setTransition(undefined);
    },
  });

  const { mutate: chooseNewChamp } = useMutation({
    mutationFn: client.chooseNewChamp,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });
      updateDebouncedPoints(0);
      setTransition(undefined);
    },
  });

  const { mutate: resetChampionsProgress } = useMutation({
    mutationFn: client.resetChampionsProgress,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PROFILE_CHAMPIONS],
      });

      updateDebouncedPoints(0);
      setTransition(undefined);
    },
  });

  return (
    <TransmogrifierContext.Provider
      value={{
        debouncedPoints,
        earnedPoints,
        canEarnPoints,
        transitionTypes,
        transition,
        setTransition,
        earnPoints,
        levelUpChamp,
        chooseNewChamp,
        resetChampionsProgress,
        onPointsAdded,
      }}
    >
      <>
        {children}

        {transition === transitionTypes.pointAdded && <CongratulationsDialog />}

        {transition === transitionTypes.levelUp && (
          <LevelUpDialog onClose={levelUpChamp} />
        )}

        {transition === transitionTypes.graduate && <GraduationDialog />}
      </>
    </TransmogrifierContext.Provider>
  );
}

export function useTransmogrifier({ onPointsEarned = () => {} } = {}) {
  const router = useRouter();
  const pathname = usePathname();
  const {
    debouncedPoints: points,
    earnedPoints,
    canEarnPoints,
    transition,
    setTransition,
    earnPoints,
    levelUpChamp,
    chooseNewChamp,
    resetChampionsProgress,
    onPointsAdded,
  } = React.useContext(TransmogrifierContext);
  const { user } = useUser();
  const champion = useCurrentChampion();
  const { intendedPath } = useIntendedPath();

  const isLoggedOutUser = !user.isLoggedIn;

  const canGraduate = !champion
    ? false
    : champion.reachedMaxLevel &&
      !isLoggedOutUser &&
      points >= champion.pointsPerLevel;

  const canLevelUp = !champion
    ? false
    : points >= champion.pointsPerLevel &&
      !isLoggedOutUser &&
      !champion.reachedMaxLevel;

  const canRegister = !champion
    ? false
    : points >= champion.pointsPerLevel && isLoggedOutUser;

  const redirectToRegistration = React.useCallback(() => {
    const currentIntendedPath = intendedPath || pathname;

    router.push(
      `${ROUTE_PATHS.REGISTRATION}?intended_path=${currentIntendedPath}`,
      undefined,
      {
        sourceElement: SECTIONS_TYPES.TRANSMOGRIFIER,
        sourceName: SECTIONS.CREATE_ACCOUNT_BUTTON,
      },
    );

    setTransition(null);
  }, [intendedPath, router, pathname, setTransition]);

  React.useEffect(() => {
    const unsubscribe = subscribeToPointsAdded((amount) => {
      onPointsEarned(amount);
    });

    return () => {
      unsubscribe();
    };
  }, [onPointsEarned]);

  return {
    points,
    earnedPoints,
    canEarnPoints,
    canGraduate,
    canLevelUp,
    canRegister,
    onPointsAdded,
    transitionTypes,
    transition,
    setTransition,
    redirectToRegistration,
    levelUpChamp,
    earnPoints,
    chooseNewChamp,
    resetChampionsProgress,
  };
}

function CongratulationsDialog() {
  const champion = useCurrentChampion();
  const { champions } = useChampionsQuery();
  const { user } = useUser();
  const { profile } = useProfile();
  const {
    points,
    earnedPoints,
    canGraduate,
    canLevelUp,
    canRegister,
    levelUpChamp,
    redirectToRegistration,
    setTransition,
  } = useTransmogrifier();
  const isLoggedOutUser = !user.isLoggedIn;
  const audioPlaybackDelay = 1000; // This match the delay-1000 on the progress bar.

  const phaseImage = React.useMemo(() => {
    if (!champion || !champions) return null;

    const currentChampionWithImages = champions.find(
      (champ) => champ.id === champion.champId,
    );

    if (isLoggedOutUser) {
      return get(currentChampionWithImages, "images.phase1.full", null);
    }

    return get(
      currentChampionWithImages,
      ["images", `phase${champion.level}`, "full"],
      null,
    );
  }, [champion, champions, isLoggedOutUser]);

  const randomChampionMessage = React.useMemo(() => {
    if (!champion) return null;

    const randomMessage =
      champion.messages[Math.floor(Math.random() * champion.messages.length)];

    return randomMessage.text;
  }, [champion]);

  const championRole = React.useMemo(() => {
    if (!champion || !champions) return null;

    const currentChampionWithRole = champions.find(
      (champ) => champ.id === champion.champId,
    );

    return get(currentChampionWithRole, "role", null);
  }, [champion, champions]);

  const callToActionText = canGraduate
    ? "Graduate Champ"
    : canLevelUp
    ? "Level Up!"
    : canRegister
    ? "Create a Free Account"
    : "Keep Going";

  React.useEffect(() => {
    const audio = new Audio(
      `${URLS.GN_ASSETS_BASE_URL}/web_public/progress-bar-sound-1.mp3`,
    );

    const timeoutId = setTimeout(() => {
      audio.play();
    }, audioPlaybackDelay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const { logEvent: logLevelUpVideoSkippedEvent } = useLogEvent({
    event: "Level Up Video Skipped",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { logEvent: logLevelUpVideoEvent } = useLogEvent({
    event: "Level Up Video",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const levelUpChampion = React.useCallback(
    (skip) => {
      if (skip === true) {
        levelUpChamp();
        logLevelUpVideoSkippedEvent({
          newChampLevel: String(champion.level + 1),
        });
      } else {
        setTransition(transitionTypes.levelUp);
        logLevelUpVideoEvent({ newChampLevel: String(champion.level + 1) });
      }
    },
    [
      levelUpChamp,
      logLevelUpVideoSkippedEvent,
      champion?.level,
      logLevelUpVideoEvent,
      setTransition,
    ],
  );

  const graduateChampion = React.useCallback(() => {
    setTransition(transitionTypes.graduate);
  }, [setTransition]);

  const handleOnSubmit = () => {
    if (canGraduate) {
      graduateChampion();
    } else if (canLevelUp) {
      levelUpChampion();
    } else if (canRegister) {
      redirectToRegistration();
    } else {
      setTransition(null);
    }
  };

  const handleOnSkip = () => {
    if (canLevelUp) {
      levelUpChampion(true);
    } else if (canGraduate) {
      setTransition(null);
    }
  };

  if (!champion) return null;

  return (
    <Modal className="z-50 pr-0 pl-0" isOpen>
      <div
        className="w-screen h-screen flex flex-col justify-center bg-repeat"
        style={{
          backgroundImage: `url(${URLS.GN_ASSETS_BASE_URL}/web_public/images/background-gn-logs.svg)`,
        }}
      >
        <div className="flex flex-col justify-center container h-full">
          <div className="hidden md:flex flex-row items-center mb-1">
            <div className="flex flex-col max-w-[30%] h-fit mb-4">
              <Image
                className="object-contain shrink-0"
                sources={
                  phaseImage
                    ? {
                        "regular@1x": phaseImage["1x"],
                        "regular@2x": phaseImage["2x"],
                        "regular@3x": phaseImage["3x"],
                      }
                    : null
                }
                alt=""
              />
              <div className="flex flex-row text-md lg:text-[20px] text-white">
                <div>
                  <div className="font-bold">{champion.name}</div>
                  {championRole && <div>{championRole}</div>}
                </div>
              </div>
            </div>

            <div className="flex flex-row h-fit overflow-hidden self-center -translate-y-4 mr-4">
              <svg
                className="self-center -mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width={37}
                height={32}
                fill="none"
              >
                <path
                  fill="#FFD966"
                  d="M1.478 9.557c11.376 1.226 29.936 5.063 34.434 21.85l.208-28.17C22.675-3.824 8.945 2.223.992 7.809c-.741.52-.416 1.65.486 1.748Z"
                />
              </svg>

              <div className="flex flex-col space-y-4">
                <div
                  className="flex flex-col text-[#18181B] h-full max-w-screen-sm rounded-lg p-9 space-y-4"
                  style={{ backgroundColor: "#FFD966" }}
                >
                  <span className="text-lg leading-7 lg:text-xl lg:leading-10 font-bold">
                    Congratulations!
                    <br />
                    {`You've Earned ${
                      earnedPoints > 1 ? `${earnedPoints} Points` : "a Point"
                    }!`}
                  </span>
                  <span className="text-sm leading-6 lg:text-lg lg:leading-8 font-semibold">
                    {profile
                      ? `Hey, ${profile.name} ${
                          profile.school.id ? `from ${profile.school.name}` : ""
                        }`
                      : "Hey, buddy!"}
                  </span>
                  <span className="text-sm leading-6 lg:text-lg lg:leading-8 italic">
                    {randomChampionMessage}
                  </span>
                  <div className="space-x-6">
                    <Button
                      variant={BUTTON_VARIANTS.vivid}
                      size={BUTTON_SIZES.md}
                      className="w-fit"
                      onPress={handleOnSubmit}
                    >
                      {callToActionText}
                    </Button>
                    {(canGraduate || canLevelUp) && (
                      <Pressable onPress={handleOnSkip}>Keep Playing</Pressable>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-fit mt-auto ml-auto mb-4 px-2 lg:px-4 py-2 bg-purple border border-purple rounded-full flex items-center justify-center">
              <StarIcon className="w-9 h-9 lg:w-10 lg:h-10 text-yellow-400 mr-1" />
              <div className="w-9 h-9 lg:w-10 lg:h-10 flex items-center justify-center">
                <span className="text-white text-lg lg:text-[38px] font-bold space-x-[2px]">
                  <span>+</span>
                  <span>{earnedPoints}</span>
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-auto space-y-2 md:hidden">
            <div className="flex flex-row  w-fit">
              <div className="flex max-w-[45%]">
                <Image
                  className="object-contain shrink-0"
                  sources={
                    phaseImage
                      ? {
                          "regular@1x": phaseImage["1x"],
                          "regular@2x": phaseImage["2x"],
                          "regular@3x": phaseImage["3x"],
                        }
                      : null
                  }
                  alt=""
                />
              </div>

              <div className="flex flex-col ml-4 self-center text-white text-md">
                <div>
                  <div className="font-bold">{champion.name}</div>
                  {championRole && <div>{championRole}</div>}
                </div>
              </div>
            </div>

            <div
              className="flex flex-col relative text-[#18181B] h-full max-w-screen-sm rounded-lg p-4 space-y-2"
              style={{ backgroundColor: "#FFD966" }}
            >
              <svg
                className="top-0 right-1/4 md:right-1/2 rotate-90 -translate-y-full  absolute"
                xmlns="http://www.w3.org/2000/svg"
                width={37}
                height={32}
                fill="none"
              >
                <path
                  fill="#FFD966"
                  d="M1.478 9.557c11.376 1.226 29.936 5.063 34.434 21.85l.208-28.17C22.675-3.824 8.945 2.223.992 7.809c-.741.52-.416 1.65.486 1.748Z"
                />
              </svg>

              <span className="text-lg leading-7 font-bold">
                Congratulations!
                <br />
                {`You've Earned ${
                  earnedPoints > 1 ? `${earnedPoints} Points` : "a Point"
                }!`}
              </span>
              <span className="text-sm leading-6 font-semibold">
                {profile
                  ? `Hey, ${profile.name} ${
                      profile.school.id ? `from ${profile.school.name}` : ""
                    }`
                  : "Hey, buddy!"}
              </span>

              <span className="text-sm leading-6 italic">
                {randomChampionMessage}
              </span>
              <div className="space-x-3">
                <Button
                  variant={BUTTON_VARIANTS.vivid}
                  size={BUTTON_SIZES.md}
                  className="w-fit"
                  onPress={handleOnSubmit}
                >
                  {callToActionText}
                </Button>
                {(canGraduate || canLevelUp) && (
                  <Pressable onPress={handleOnSkip}>Keep Playing</Pressable>
                )}
              </div>
            </div>
          </div>

          <div className="mt-auto md:mt-0 mb-4">
            <div className="w-fit ml-auto md:hidden mb-4 px-2 bg-purple border border-purple rounded-full flex items-center justify-center">
              <StarIcon className="w-9 h-9 text-yellow-400 mr-1" />
              <div className="w-9 h-9 flex items-center justify-center">
                <span className="text-white text-lg font-bold space-x-[2px]">
                  <span>+</span>
                  <span>{earnedPoints}</span>
                </span>
              </div>
            </div>

            <PointsProgressBar
              value={points}
              max={champion.pointsPerLevel}
              showTrophy={false}
              animateOnMount={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

function Transmogrifier({
  title,
  jwPlayerId,
  award,
  isOpen,
  action,
  onActionClick,
  onPrintAward,
}) {
  const [showActions, setShowActions] = React.useState(false);
  // award prop is cached as the champ data is stored inside the user object so updates to the user object will the Transmogrifier is open will bring the next level award.
  const cachedAward = React.useRef(award);
  const onVideoStart = useOnce(() => {
    setTimeout(() => setShowActions(true), 5000);
  });

  React.useEffect(() => {
    if (!isOpen) {
      setShowActions(false);
    }
  }, [isOpen]);

  return (
    <Modal className="z-50 items-stretch" isOpen={isOpen}>
      <div className="absolute inset-0 bg-white gn-transmogrifier-video">
        <Player
          playbackId={jwPlayerId}
          autoPlay={true}
          stretching="fill"
          hideControls={true}
          onPlay={onVideoStart}
        />

        <div
          className={twMerge(
            "opacity-0 transition-opacity",
            showActions && "opacity-100",
          )}
        >
          <h1 className="absolute left-1/2 -translate-x-1/2 top-10 w-full text-white text-xl sm:text-2xl lg:text-3xl text-center font-display">
            {title}
          </h1>

          <div
            className={twMerge(
              "absolute w-full left-0 bottom-0 flex",
              "bg-gradient-to-b from-transparent to-black",
              "max-sm:flex-col max-sm:items-center max-sm:space-y-sm max-sm:pb-4 max-sm:px-4",
              "sm:justify-center sm:space-x-md pt-32 sm:pb-10 sm:px-10",
            )}
          >
            {cachedAward.current && (
              <Pressable
                onPress={onPrintAward}
                elementType="a"
                className="w-full py-2 sm:w-72 sm:py-4 rounded-md bg-white font-bold text-center transition flex justify-center items-center hover:bg-purple hover:border-purple hover:text-white focus:bg-purple focus:border-purple focus:text-white"
                href={cachedAward.current}
                target="_blank"
              >
                Print Award
              </Pressable>
            )}

            <button
              className="w-full py-2 sm:w-72 sm:py-4 border-white border-2 rounded-md text-white font-bold transition flex justify-center items-center hover:bg-white hover:text-black focus:bg-white focus:text-black"
              onClick={onActionClick}
            >
              {action}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function LevelUpDialog({ onClose }) {
  const champ = useCurrentChampion();
  const level = get(champ, "level") + 1;
  const award = get(champ, `levelUpCertificate${level}`);
  const jwPlayerId = get(champ, "phaseTransition.video.jwPlayerId");

  const { logEvent } = useLogEvent({
    event: "Champ Achievement Printed",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  if (!champ) return null;

  return (
    <Transmogrifier
      title={`You leveled up ${champ.name}`}
      award={award}
      jwPlayerId={jwPlayerId}
      isOpen
      action="Continue GoNoodling"
      onActionClick={onClose}
      onPrintAward={() =>
        logEvent({ champName: champ.name, level: String(level) })
      }
    />
  );
}

function GraduationDialog() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const champion = useCurrentChampion();
  const { levelUpChamp, resetChampionsProgress, chooseNewChamp } =
    useTransmogrifier();
  const { champions } = useChampionsQuery();

  const award = get(champion, "graduationCertificate");
  const jwPlayerId = get(champion, "phaseTransition.video.jwPlayerId");

  const { logEvent } = useLogEvent({
    event: "Champ Achievement Printed",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const { logEvent: logNewChampSelectedEvent } = useLogEvent({
    event: "New Champ Selected",
    options: {
      includeReferrer: false,
      includeSourcePage: false,
      includeSourcePageType: false,
    },
  });

  const onChampSelected = (id, resetChampionsLevels) => {
    levelUpChamp(null, {
      onSuccess: () => {
        const selectedChamp = champions.find((c) => c.id === id);
        logNewChampSelectedEvent({
          champName: selectedChamp.name,
          prevChampName: champion.name,
        });

        if (resetChampionsLevels) {
          resetChampionsProgress(id);
        } else {
          chooseNewChamp(id);
        }
      },
    });
  };

  if (!jwPlayerId || !champion) {
    return null;
  }

  return (
    <>
      <Transmogrifier
        title={`You maxed out ${champion.name}`}
        award={award}
        jwPlayerId={jwPlayerId}
        isOpen
        action="Choose a new champ"
        onActionClick={() => setOpenDialog(true)}
        onPrintAward={() =>
          logEvent({ champName: champion.name, level: "Max" })
        }
      />

      {openDialog && (
        <GraduatedChampSelection
          champ={champion}
          onChampSelected={onChampSelected}
        />
      )}
    </>
  );
}

TransmogrifierProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
