import React from "react";
import PropTypes from "prop-types";
import {
  Image,
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  RadioGroup,
} from "@gonoodle/gn-universe-ui";
import { twMerge } from "tailwind-merge";

import { useLogEvent } from "../contexts/Analytics";
import { useChampionsQuery, useChampion } from "../hooks";

function ChampionCard({ champion, isGraduated = false }) {
  return (
    <RadioGroup.Item
      value={String(champion.id)}
      disabled={isGraduated}
      className="flex flex-col items-center text-center gap-2 p-2 group outline-none"
    >
      <div className="relative w-full aspect-[3/2] overflow-hidden rounded-lg transition-shadow group-data-[state=checked]:ring ring-white ring-offset-2 ring-offset-gray-900">
        <span className="absolute inset-0 bg-purple transition-colors group-focus-within:bg-white group-data-[disabled]:opacity-50" />

        <Image
          className="absolute inset-0 w-full group-data-[disabled]:opacity-50"
          sources={{
            "regular@1x": isGraduated
              ? champion.images.phase5.headshot["1x"]
              : champion.images.phase1.headshot["1x"],
            "regular@2x": isGraduated
              ? champion.images.phase5.headshot["2x"]
              : champion.images.phase1.headshot["2x"],
            "regular@3x": isGraduated
              ? champion.images.phase5.headshot["3x"]
              : champion.images.phase1.headshot["3x"],
          }}
          alt=""
        />

        {isGraduated && (
          <span
            className="text-xs absolute right-2 top-2 uppercase py-1 px-1.5 rounded-md font-semibold bg-white"
            aria-hidden="true"
          >
            Graduated
          </span>
        )}
      </div>

      <div className="flex flex-col group-data-[disabled]:opacity-50">
        <span className="text-lg text-white font-semibold my-1">
          {champion.name}
        </span>

        <span className="text-xs text-purple-300">{champion.role}</span>
      </div>
    </RadioGroup.Item>
  );
}

export default function ChampionSelect({
  defaultSelectedChampion,
  disabled = false,
  unavailableChampionsIDs = [],
  onChange = () => {},
}) {
  const [isUsingRandom, setIsUsingRandom] = React.useState(false);
  const [selectedChampionId, setSelectedChampionId] = React.useState(
    String(defaultSelectedChampion?.id),
  );
  const { champions } = useChampionsQuery();
  const selectedChampion = useChampion(undefined, Number(selectedChampionId));

  const { logEvent: logChampRandomizerUsed } = useLogEvent({
    event: "Champ Randomizer Used",
    properties: {},
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: false,
    },
  });

  const { logEvent: logChampProfileViewed } = useLogEvent({
    event: "Champ Profile Viewed",
    properties: {
      champName: selectedChampion?.name,
    },
    options: {
      includeReferrer: false,
      includeSourcePage: true,
      includeSourcePageType: false,
    },
  });

  function selectRandomChampion() {
    const unselectedChampions = champions.filter(
      (champ) =>
        champ.id !== selectedChampionId &&
        !unavailableChampionsIDs.includes(champ.id),
    );

    if (unselectedChampions?.length) {
      const randomChampion =
        unselectedChampions[
          Math.floor(Math.random() * unselectedChampions.length)
        ];

      setIsUsingRandom(true);
      setSelectedChampionId(String(randomChampion.id));
      logChampRandomizerUsed({ champName: randomChampion.name });
    }
  }

  return (
    <div className="flex items-start max-md:flex-col max-md:items-end w-full">
      <div className="flex flex-1 w-full md:px-md lg:px-lg">
        <RadioGroup.Root
          value={String(selectedChampionId)}
          headless={true}
          disabled={disabled}
          className="grid gap-x-md gap-y-md grid-cols-2 lg:grid-cols-3"
          onValueChange={(newChampionId) => {
            setSelectedChampionId(newChampionId);
            logChampProfileViewed();
          }}
        >
          {champions.map((champion) => (
            <ChampionCard
              key={champion.id}
              champion={champion}
              isGraduated={unavailableChampionsIDs.includes(champion.id)}
            />
          ))}
        </RadioGroup.Root>
      </div>

      <div className="sticky mt-2 flex flex-col space-y-2 md:top-lg md:mr-md lg:mr-lg max-md:bottom-sm max-md:w-full">
        <div
          className={twMerge(
            "flex flex-col items-center bg-purple p-6 rounded-md max-md:shadow-xl",
            !selectedChampion && "bg-purple-900",
          )}
        >
          <p
            className={twMerge(
              "gn-headline-sm text-white",
              !selectedChampion && "opacity-50",
            )}
          >
            {selectedChampion?.name ?? "Champ"}
          </p>
          {selectedChampion?.role && (
            <p className="text-sm mt-sm text-purple-300 text-center">
              {selectedChampion.role}
            </p>
          )}
          <div className="relative w-[200px] aspect-1 md:w-[300px] lg:w-[400px] xl:w-[500px]">
            {selectedChampion && (
              <Image
                className="absolute inset-0 w-full h-full object-contain p-8"
                sources={{
                  "regular@1x": selectedChampion.images.phase1.full["1x"],
                  "regular@2x": selectedChampion.images.phase1.full["2x"],
                  "regular@3x": selectedChampion.images.phase1.full["3x"],
                }}
                alt=""
              />
            )}
          </div>

          {selectedChampion?.background && (
            <p className="text-sm my-sm text-purple-300 w-[200px] md:w-[300px] lg:w-[400px] xl:w-[500px] text-center">
              {selectedChampion.background}
            </p>
          )}

          <Button
            className="w-full"
            testId="play-with-champion"
            variant={BUTTON_VARIANTS.light}
            size={BUTTON_SIZES.lg}
            disabled={!selectedChampion || disabled}
            onPress={() => onChange(selectedChampion, isUsingRandom)}
          >
            {selectedChampion
              ? `Play with ${selectedChampion.name}`
              : `Choose your Champ`}
          </Button>
        </div>

        <Button
          className="w-full"
          variant={BUTTON_VARIANTS.dark}
          size={BUTTON_SIZES.lg}
          disabled={disabled}
          onPress={selectRandomChampion}
        >
          <span className="text-gray-400">Can&apos;t pick?</span>&nbsp;Give me a
          random Champ
        </Button>
      </div>
    </div>
  );
}

ChampionSelect.propTypes = {
  defaultSelectedChampion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    background: PropTypes.string,
    images: PropTypes.shape({
      phase1: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase2: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase3: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase4: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase5: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
  }),
  disabled: PropTypes.bool,
  unavailableChampionsIDs: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

ChampionCard.propTypes = {
  champion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    background: PropTypes.string,
    images: PropTypes.shape({
      phase1: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase2: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase3: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase4: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
      phase5: PropTypes.shape({
        headshot: PropTypes.shape({
          "1x": PropTypes.string.isRequired,
          "2x": PropTypes.string.isRequired,
          "3x": PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
  }).isRequired,
  isGraduated: PropTypes.bool,
};
